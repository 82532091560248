import { styled as styledMui } from '@mui/material/styles';

import Icon from 'components/Icon';
import { DateTimePicker } from '@mui/x-date-pickers';

export const StyledIcon = styledMui(Icon)(({ theme }) => ({
  svg: {
    path: {
      fill: theme.palette.icon.primary.color,
    },
  },
}));

export const StyledDateTimePicker = styledMui(DateTimePicker)(({ theme, error, readOnly }) => ({
  '.MuiInputBase-root': {
    cursor: 'pointer',
    borderRadius: 4,
    backgroundColor: theme.palette.input.color,
    border: `1px solid ${theme.palette.input.border.color}`,
    input: {
      cursor: 'pointer',
    },
    ...(error && { border: `1px solid ${theme.palette.error.main}` }),
    ...(readOnly && {
      border: `1px solid ${theme.palette.input.readonly.order}`,
      backgroundColor: 'transparent',
      pointerEvents: 'none',
    }),
    '&:hover': {
      backgroundColor: theme.palette.input.hover.color,
    },
  },
  '.MuiInputBase-root.Mui-focused': {
    border: `1px solid ${theme.palette.input.focused.border}`,
  },
  '.MuiInputBase-root.Mui-disabled': {
    backgroundColor: theme.palette.input.disabled.color,
    input: {
      cursor: 'auto',
    },
  },
  '.MuiInputLabel-root.Mui-disabled': {
    color: theme.palette.input.disabled.text,
  },
}));
