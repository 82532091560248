import React from "react";
import dayjs from "dayjs";
import { FormControl, FormHelperText } from "@mui/material";

import { StyledDateTimePicker, StyledIcon } from "./styles";
import { renderDateViewCalendar, renderTimeViewClock } from "@mui/x-date-pickers";

const inputProps = {
  disableUnderline: true,
  endAdornment: <StyledIcon icon="calendar" />,
};

const DateTimePicker = ({
  required,
  disabled,
  label,
  error,
  value = null,
  minDate,
  maxDate,
  setValue,
  readOnly,
  type,
}) => {
  const handleDateChange = (newDate) => {
    if (setValue) {
      setValue(newDate, false);
    }
  };
  return (
    <FormControl fullWidth>
      <StyledDateTimePicker
        clearable
        defaultValue={dayjs(value).toDate()}
        variant="dialog"
        // inputVariant="filled"
        fullWidth
        format="MM/dd/yyyy h:mm a"
        openTo="month"
        views={
          type === "time"
            ? ["hours", "minutes"]
            : ["year", "month", "day", "hours", "minutes"]
        }
        label={label}
        hideTabs={type === "time"}
        disabled={disabled && !readOnly}
        value={value === "" ? null : dayjs(value).toDate()}
        required={required}
        readOnly={readOnly}
        InputProps={inputProps}
        error={!!error}
        minDate={minDate}
        maxDate={maxDate}
        onChange={handleDateChange}
        viewRenderers={{
          hours: renderTimeViewClock,
          minutes: renderTimeViewClock,
          seconds: renderTimeViewClock,
          day: renderDateViewCalendar,
          month: renderDateViewCalendar,
          year: renderDateViewCalendar,
        }}
      />
      <FormHelperText variant="filled">{error || " "}</FormHelperText>
    </FormControl>
  );
};

export default DateTimePicker;
